<form [formGroup]="contactForm" (ngSubmit)="onSendForm()">
	<div class="contact_container">
		<div>
			<!-- Name -->
			<mat-form-field appearance="outline">
				<mat-label>Name</mat-label>
				<input required matInput placeholder="Name" formControlName="name" />
				<mat-error *ngIf="contactForm.get('name').hasError('required')"
					>Name is required</mat-error
				>
			</mat-form-field>

			<!-- email -->
			<mat-form-field appearance="outline">
				<mat-label>Email</mat-label>
				<input matInput rows="1" formControlName="email" />
			</mat-form-field>

			<!-- project description -->
			<mat-form-field appearance="outline">
				<mat-label>Project Description</mat-label>
				<textarea
					matInput
					rows="1"
					formControlName="projectdescription"
				></textarea>
			</mat-form-field>

			<!-- url -->
			<mat-form-field appearance="outline">
				<mat-label>Website URL</mat-label>
				<input matInput rows="1" formControlName="url" />
			</mat-form-field>
		</div>
	</div>
</form>
