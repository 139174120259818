<div class="pricing" *ngIf="showPricingPanel">
	<div class="pricing--cards-container">
		<!-- INDIE PLAN -->
		<div class="pricing--card plan-indie order-1">
			<h2 class="pricing--plan-title">Indie Plan</h2>
			<h3 class="pricing--plan-team">1 developer</h3>
			<img class="pricing--plan-price" alt="FREE instead of $9.99 per month"
				src="/assets/images/pricing-plan1-price.png" />
			<p class="pricing--plan-price-details">
				for the first 30days, <br />then <b>9.99$/mo</b>
			</p>
			<hr class="pricing--plan-divider" />
			<ul class="pricing--plan-features">
				<li>Access PLAY´s Unity, UnReal and GoDot SDKs</li>
				<li>Connect your games onchain seamlessly</li>
				<li>PLAY´s ingame multichain wallet</li>
				<li>Turnkey NFT creation tooling</li>
				<li>Plug-&-play smart contracts</li>
				<li>Onchain analytics dashboard</li>
				<li>Unlock onchain monetization funnels</li>
			</ul>

			<button (click)="choosePlan('indie')" class="pricing--plan-button action-button-violet">
				<span>Choose Plan</span>
			</button>
		</div>

		<!-- PRO PLAN -->
		<div class="pricing--card plan-pro md:order-2 order-3 horizontal:order-3 border-none">
			<h2 class="pricing--plan-title">PRO Plan</h2>
			<h3 class="pricing--plan-team">unlimited</h3>

			<p class="pricing--plan-price-details">Contact us for billing options</p>
			<hr class="pricing--plan-divider" />
			<ul class="pricing--plan-features compact">
				<li>Access PLAY´s Unity, UnReal and GoDot SDKs</li>
				<li>Connect your games onchain seamlessly</li>
				<li>PLAY´s ingame multichain wallet</li>
				<li>Turnkey NFT creation tooling</li>
				<li>Plug-&-play smart contracts</li>
				<li>Onchain analytics dashboard</li>
				<li>Ingame marketplace</li>
				<li>Multiplatform distribution tooling : ie - Telegram games</li>
				<li>Telegram whitelable games store</li>
				<li>Unlock onchain monetization funnels</li>
				<li>
					Team management dashboard <span class="badge">coming soon</span>
				</li>
				<li>UGC ingame tooling<span class="badge">coming soon</span></li>
				<li>Onchain UA campaigns<span class="badge">coming soon</span></li>
			</ul>

			<button (click)="choosePlan('pro')" class="pricing--plan-button action-button-violet">
				<span>Choose Plan</span>
			</button>
		</div>

		<!-- STUDIO PLAN -->
		<div class="pricing--card plan-studio md:order-3 order-2 horizontal:order-2">
			<h2 class="pricing--plan-title">Studio Plan</h2>
			<h3 class="pricing--plan-team">2 - 5 developers</h3>
			<img class="pricing--plan-price" alt="FREE instead of $15.99 per month"
				src="/assets/images/pricing-plan2-price.png" />
			<p class="pricing--plan-price-details">
				for the first 30days, <br />then <b>15.99$/mo</b>
			</p>
			<hr class="pricing--plan-divider" />
			<ul class="pricing--plan-features compact">
				<li>Access PLAY´s Unity, UnReal and GoDot SDKs</li>
				<li>Connect your games onchain seamlessly</li>
				<li>PLAY´s ingame multichain wallet</li>
				<li>Turnkey NFT creation tooling</li>
				<li>Plug-&-play smart contracts</li>
				<li>Onchain analytics dashboard</li>
				<li>Unlock onchain monetization funnels</li>
				<li>Team management dashboard<span class="badge">coming soon</span></li>
			</ul>

			<button (click)="choosePlan('studio')" class="pricing--plan-button action-button-violet">
				<span>Choose Plan</span>
			</button>
		</div>
	</div>
</div>