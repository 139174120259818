import { Component, OnInit } from '@angular/core';
import { AuthNewService } from 'app/core/auth/auth.new.service';
import { ProjectService } from 'app/core/services/project.service';

const STORED_PLAN_NAME = 'play-pricing-plan';
const STORED_PLAN_STATE = 'play-pricing-plan-state';

@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
	showPricingPanel: boolean = false;


	constructor(
		private authService: AuthNewService,
		private projectService: ProjectService,
	) { }

	ngOnInit(): void {
		const storedPlan = localStorage.getItem(STORED_PLAN_NAME);
		if (!storedPlan) {
			this.showPricingPanel = true;
			document.body.style.overflow = 'hidden';
		} else {
			if (localStorage.getItem(STORED_PLAN_STATE) === 'pending') {
				this.projectService.setDeveloperPlan(storedPlan).then((res) => {
					localStorage.setItem(STORED_PLAN_STATE, 'selected');
				});
			}
		}
	}

	choosePlan(plan) {
		if (this.authService.isAuthenticated()) {
			this.projectService.setDeveloperPlan(plan).then((res) => {
				localStorage.setItem(STORED_PLAN_NAME, plan);
				localStorage.setItem(STORED_PLAN_STATE, 'selected');
				this.showPricingPanel = false;
			});
		} else {
			localStorage.setItem(STORED_PLAN_NAME, plan);
			localStorage.setItem(STORED_PLAN_STATE, 'pending');
			this.showPricingPanel = false;
		}
		document.body.style.overflow = 'auto';
	}
}
